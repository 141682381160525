




























import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const modalError = namespace('modalError');
@Component
export default class CustomModalError extends Vue {
  /******************* Start - Vuex *******************/
  @modalError.Getter
  dialog!: boolean;

  @modalError.Getter
  message!: string;

  @modalError.Getter
  title!: string;

  @modalError.Mutation
  setDialog!: (val: boolean) => void;

  /******************* End - Vuex *******************/
}
