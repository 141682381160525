<template>
  <v-app>
    <v-navigation-drawer
      id="default-drawer"
      v-model="drawer"
      :mini-variant="mini"
      mini-variant-width="80"
      :expand-on-hover="mini"
      app
      width="260"
    >
      <v-list>
        <v-list-item-group>
          <v-list-item>
            <v-list-item-avatar class="ml-2" width="30" height="30" size="40">
              <v-img
                width="30"
                height="30"
                :src="require('@/app/assets/images/logo/logo_color_favicon.png')"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-title>
              <h1 :style="{ color: $vuetify.theme.themes.dark.primary, fontSize: '30px' }">
                FIRECITY
              </h1>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <!--      <v-divider></v-divider>-->
      <v-list-item>
        <v-list-item-icon>
          <v-badge bordered bottom color="green accent-4" dot offset-x="10" offset-y="10">
            <v-avatar color="primary" size="35">
              <!--              <v-img src="https://cdn.vuetifyjs.com/images/lists/2.jpg"></v-img>-->
              <span class="white--text text-h5" v-if="user">{{ user[0] }}</span>
            </v-avatar>
          </v-badge>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Hola, <strong v-if="user">{{ user }}</strong> <br />
            <small class="font-weight-light">conectado</small>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>

      <template>
        <v-list rounded>
          <v-list-item-group color="primary">
            <v-list-item
              :to="{ path: item.href }"
              link
              v-for="(item, i) in items"
              v-bind="$attrs"
              v-on="$listeners"
              :key="i"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-list-item-group>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-app-bar app height="57" color="primary" dense dark>
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.smAndDown"
        @click.stop="mini = !mini"
      ></v-app-bar-nav-icon>

      <v-app-bar-nav-icon
        v-if="!$vuetify.breakpoint.smAndDown"
        @click="handleDrawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title>
        {{ $route.meta.nameComponent }}
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn
        @click="logout"
        tile
        color="error"
        class="hidden-sm-and-down white--text rounded"
        depressed
      >
        <v-icon left> mdi-power </v-icon>
        Cerrar Sesión
      </v-btn>

      <template>
        <v-menu
          bottom
          left
          min-width="100"
          offset-y
          origin="top right"
          transition="scale-transition"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>

          <v-list>
            <!--            <v-list-item @click="() => {}">
              <v-list-item-title><v-icon>mdi-account</v-icon> Perfil</v-list-item-title>
            </v-list-item>
            <v-list-item @click="() => {}">
              <v-list-item-title><v-icon>mdi-cog</v-icon> Opciones</v-list-item-title>
            </v-list-item>-->
            <v-list-item @click="logout">
              <v-list-item-title><v-icon>mdi-logout</v-icon> Salir</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-container tag="div" fluid>
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-container>
    </v-main>

    <custom-modal-error></custom-modal-error>
    <custom-snackbar></custom-snackbar>
  </v-app>
</template>
<script>
import { mapActions } from 'vuex';
import { Auth } from 'aws-amplify';
import CustomModalError from '@/app/common/components/custom-modal-error/CustomModalError';
import CustomSnackbar from '@/app/common/components/custom-snackbar/CustomSnackbar';

export default {
  name: 'MainLayout',
  components: { CustomSnackbar, CustomModalError },
  data: () => ({
    selectedItem: 1,
    items: [
      { text: 'Usuarios', icon: 'mdi-account-circle', href: '/dashboard/users' },
      { text: 'Dispositivos', icon: 'mdi-office-building', href: '/dashboard/devices' },
      { text: 'Inmuebles', icon: 'mdi-monitor-cellphone-star', href: '/dashboard/spaces' },
      /*   { text: 'Inmuebles', icon: 'mdi-monitor-cellphone-star', href: '/dashboard/spaces-new' },*/
      { text: 'Compañias', icon: 'mdi-domain', href: '/dashboard/companies' },
    ],
    drawer: true,
    mini: false,
    user: null,
  }),
  methods: {
    ...mapActions('auth', ['signOut']),
    async logout() {
      await this.signOut();
    },
    handleDrawer() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        if (this.mini) {
          this.mini = false;
        }
        this.drawer = !this.drawer;
      } else {
        this.mini = !this.mini;
      }
    },
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    },
  },
  async created() {
    await Auth.currentAuthenticatedUser().then((user) => {
      this.user = user.attributes.name;
    });
  },
};
</script>

<style lang="sass">
#default-drawer
  .v-list-item
    margin-bottom: 8px

  .v-list-group__header__prepend-icon,
  .v-list-item__icon
    margin-top: 12px
    margin-bottom: 12px
    margin-left: 4px

  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important
</style>
