











import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const snackbar = namespace('snackbar');
@Component
export default class CustomSnackbar extends Vue {
  @snackbar.Getter
  snackbar!: boolean;
  @snackbar.Getter
  knowledge!: number;

  get computedSnackbar(): boolean {
    return this.snackbar;
  }
  get computedKnowledge(): number {
    return this.knowledge;
  }
}
